import * as fromProject from './project.reducer';
import * as SessionSelectors from './../session/session.selectors';
import * as ContractSelectors from './../contract/contract.selectors';
//import * as LocationSelectors from './../location/location.selectors';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { Meta } from '@gridscale/gsclient-js';
import { selectRouteNestedParam } from '../router/router.selectors';
import { Project } from './project.reducer';

export const selectProjectState = createFeatureSelector<fromProject.State>(fromProject.featureKey);

/****************
 * GET Single
 ****************/

/**
 * get current project
 */
export const getCurrentProject = createSelector(
  selectProjectState,
  SessionSelectors.getCurrentProjectUuid,
  (state: fromProject.State, project_uuid) => _.get(state, ['entities', project_uuid, 'data']) || {}
);

export const getCurrentProjectLocationUuid = createSelector(selectProjectState, SessionSelectors.getCurrentProjectUuid, (state: fromProject.State, project_uuid) =>
  _.get(state, ['entities', project_uuid, 'data', 'location_uuid'])
);

/**
 * get current project last error
 */
export const getCurrentProjectError = createSelector(selectProjectState, SessionSelectors.getCurrentProjectUuid, (state: fromProject.State, project_uuid) =>
  _.get(state, ['entities', project_uuid, 'error'])
);

/**
 * get if current project is loading
 */
export const isCurrentProjectLoading = createSelector(selectProjectState, SessionSelectors.getCurrentProjectUuid, (state: fromProject.State, project_uuid) =>
  _.get(state, ['entities', project_uuid, 'loading'])
);

/**
 * get if current project is ready loaded
 */
export const isCurrentProjectLoaded = createSelector(selectProjectState, SessionSelectors.getCurrentProjectUuid, (state: fromProject.State, project_uuid) =>
  _.get(state, ['entities', project_uuid, 'loaded'])
);

/****************
 * LIST
 ****************/

/****************
 * Selector to work with Projects for a Specific Contract
 ****************/
export const getProjects = (props: { contractUuid: string }) =>
  createSelector(selectProjectState, (state: fromProject.State) => _.get(state, ['list', props.contractUuid, 'data']));

export const getProjectsError = (props: { contractUuid: string }) =>
  createSelector(selectProjectState, (state: fromProject.State) =>
  _.get(state, ['list', props.contractUuid, 'error'])
);

export const isProjectsLoading = (props: { contractUuid: string }) =>
  createSelector(selectProjectState, (state: fromProject.State) =>
  _.get(state, ['list', props.contractUuid, 'loading'], false)
);

export const isProjectsLoaded = (props: { contractUuid: string }) =>
  createSelector(selectProjectState, (state: fromProject.State) =>
  _.get(state, ['list', props.contractUuid, 'loaded'], false)
);

/****************
 * Selector to work with the current Project in the Contract
 ****************/

/**
 * Filter and sory Projects here!!
 */
export const getProjectsForCurrentContractLight = createSelector(selectProjectState, ContractSelectors.getCurrentContractUuid, (state: fromProject.State, _current_contract_uuid) =>
  _.get(state, ['list', _current_contract_uuid || '', 'data'])
);

export const getProjectsForCurrentContract = createSelector(selectProjectState, ContractSelectors.getCurrentContractUuid, (state: fromProject.State, _current_contract_uuid) => {
  let all = _.get(state, ['list', _current_contract_uuid, 'data']) || [];

  if (state.listConfig?.filter && state.listConfig.filter.length > 0) {
    const searchKey = state.listConfig.filter[0].split('%')[1];
    if (searchKey) {
      all = _.filter(all, (_item: Project) => _.toInteger(_item?.name?.toLocaleUpperCase().search(searchKey.toLocaleUpperCase())) >= 0);
    }
  }

  if (state.listConfig?.sort) {
    all = _.sortBy(all, state.listConfig.sort);
  }

  return all;
});

/**
 * Filter and sory Projects here!!
 */
export const getProjectsForCurrentContractAlphabetical = createSelector(
  selectProjectState,
  ContractSelectors.getCurrentContractUuid,
  (state: fromProject.State, _current_contract_uuid) => {
    let all = _.get(state, ['list', _current_contract_uuid, 'data']) || [];

    all = _.sortBy(all, 'name');

    return all;
  }
);

/**
 * Filter and sory Projects here!!
 */
export const getDefaultProject = createSelector(getProjectsForCurrentContractAlphabetical, _all => {
  return _.find(_all, { default_in_contract: true });
});

export const isProjectsForCurrentContractLoading = createSelector(
  selectProjectState,
  ContractSelectors.getCurrentContractUuid,
  (state: fromProject.State, _current_contract_uuid) => _.get(state, ['list', _current_contract_uuid || '', 'loading'], false)
);

export const isProjectsForCurrentContractLoaded = createSelector(selectProjectState, ContractSelectors.getCurrentContractUuid, (state: fromProject.State, _current_contract_uuid) =>
  _.get(state, ['list', _current_contract_uuid || '', 'loaded'], false)
);

/**
 * Get Network list meta data (total etc.)
 */
export const getProjectsForCurrentContractMeta = createSelector(selectProjectState, getProjectsForCurrentContract, (state: fromProject.State, all) => {
  return {
    page: 0,
    count: _.size(all),
    limit: _.size(all),
    offset: 0,
    total: _.size(all)
  } as Meta;
});

/**
 * Get if Network list is loading next bunch of data
 */
export const getProjectsForCurrentContractLoadingNext = createSelector(selectProjectState, (state: fromProject.State) => _.get(state, ['loadingNext'], false));

/**
 * get if Network is busy (=loading, patching or deleting === true)
 */
export const isProjectBusy = (props: { uuid: string }) => createSelector(selectProjectState, (state: fromProject.State) =>
  _.get(state, ['loading', props.uuid]) === true || _.get(state, ['patching', props.uuid]) === true || _.get(state, ['deleting', props.uuid]) === true ? true : false
);

/**
 *
 */
export const isAnyProjectPatching = createSelector(selectProjectState, (state: fromProject.State) => {
  let hasPatchingProjects = false;
  if (_.size(state.patching) > 0) {
    hasPatchingProjects = !!_.find(state.patching, _value => {
      return _value;
    });
  }
  return !!hasPatchingProjects;
});

/**
 * get selected Network (by route param)
 */
export const getSelectedProject = createSelector(selectProjectState, selectRouteNestedParam('project_uuid'), (state: fromProject.State, project_uuid) => {
  return _.get(state, ['entities', project_uuid, 'data']) || {};
});

/**
 * get selected Network (by route param)
 */
export const getProject = (props: { uuid: string }) => createSelector(
  selectProjectState,
  (state: fromProject.State) => {
  return _.get(state, ['entities', props.uuid, 'data']) || {};
});

/**
 * get if selected Network (by route param) is loading
 */
export const isSelectedProjectLoaded = createSelector(selectProjectState, selectRouteNestedParam('project_uuid'), (state: fromProject.State, project_uuid) =>
  _.get(state, ['entities', project_uuid, 'loaded'], false)
);

/**
 * get if selected Network (by route param) is loading
 */
export const isSelectedProjectLoading = createSelector(selectProjectState, selectRouteNestedParam('project_uuid'), (state: fromProject.State, project_uuid) =>
  _.get(state, ['entities', project_uuid, 'loading'], false)
);

/**
 * get if selected Network (by route param) is loading
 */
export const isProjectLoaded = (props: { uuid: string }) => createSelector(
  selectProjectState,
  (state: fromProject.State) =>
    _.get(state, ['entities', props.uuid, 'loaded'], false)
);

/**
 * get if selected Network (by route param) is loading
 */
export const isProjectLoading = (props: { uuid: string }) => createSelector(selectProjectState, (state: fromProject.State) =>
  _.get(state, ['entities', props.uuid, 'loading'], false)
);

/**
 * get if selected Network (by route param) is loading
 */
export const isSelectedProjectPatching = createSelector(selectProjectState, selectRouteNestedParam('project_uuid'), (state: fromProject.State, project_uuid) =>
  _.get(state, ['patching', project_uuid], false)
);

export const canProjectBeDeleted = (props: { uuid: string }) => createSelector(
  isProjectBusy(props),
  getProject(props),
  getCurrentProject,
  (busy, project,current_project) => {

    if (project?.object_uuid === undefined) {
      return false;
    }

    if (busy) {
      return false;
    }
    if (project?.default_in_contract) {
      return false;
    }
    if (project?.object_uuid == current_project.object_uuid) {
      return false;
    }


    return true;

  }
);

export const getCannotDeleteReason = (props: { uuid: string }) => createSelector(
  isProjectBusy(props),
  getProject(props),
  getCurrentProject,
  (busy, project,current_project) => {

    if (project?.default_in_contract) {
      return 'PROJECT.CANNOTDELETEDEFAULT';
    }

    if (project?.object_uuid == current_project.object_uuid) {
      return 'PROJECT.CANNOTDELETECURRENT';
    }

    return undefined;
  }
);
