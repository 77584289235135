import { RequestOptions } from '@gridscale/gsclient-js';
import { createAction, props } from '@ngrx/store';
import { Project, ProjectCreate, ProjectUpdate } from './project.reducer';

export const actionPrefix = '[project]';

/********************************
 *          LIST ACTIONS        *
 ********************************/

export const setListConfig = createAction(actionPrefix + ' Set Projects List Config', props<{ config: RequestOptions }>());

export const loadProjects = createAction(actionPrefix + ' Load Projects', props<{ contractUuid: string }>());

export const loadProjectsForCurrentContract = createAction(actionPrefix + ' Load Projects for Current Contract');

export const loadProjectsForCurrentContractNext = createAction(actionPrefix + ' Load Projects for Current Contract Next');

export const loadProjectsSuccess = createAction(actionPrefix + ' Load Projects Success', props<{ contractUuid: string; data: Project[] }>());

export const loadProjectsFailure = createAction(actionPrefix + ' Load Projects Failure', props<{ contractUuid: string; error: Error }>());

/********************************
 *          GET ACTIONS        *
 ********************************/

export const loadCurrentProject = createAction(actionPrefix + ' Load Current Project');

export const loadProject = createAction(actionPrefix + ' Load Project', props<{ uuid: string }>());

export const loadProjectSuccess = createAction(actionPrefix + ' Load Project Success', props<{ data: Project; uuid: string }>());

export const loadProjectFailure = createAction(actionPrefix + ' Load Project Failure', props<{ error: Error; uuid: string }>());

/********************************
 *        CREATE ACTIONS        *
 ********************************/

export const createProject = createAction(actionPrefix + ' Create Project', props<{ data: ProjectCreate }>());

export const createProjectSuccess = createAction(actionPrefix + ' Create Project Success', props<{ uuid: string; request_id?: string }>());

export const createProjectFailure = createAction(actionPrefix + ' Create Project Failure', props<{ error: Error }>());

/********************************
 *         PATCH ACTIONS        *
 ********************************/

export const patchProject = createAction(actionPrefix + ' Patch Project', props<{ uuid: string; data: ProjectUpdate }>());

export const patchProjectSuccess = createAction(actionPrefix + ' Patch Project Success', props<{ uuid: string; request_id?: string }>());

export const patchProjectFailure = createAction(actionPrefix + ' Patch Project Failure', props<{ error: Error; uuid: string }>());

/********************************
 *        DELETE ACTIONS        *
 ********************************/

export const deleteProject = createAction(actionPrefix + ' Delete Project', props<{ uuid: string }>());

export const deleteProjectSuccess = createAction(actionPrefix + ' Delete Project Success', props<{ uuid: string; request_id?: string }>());

export const deleteProjectFailure = createAction(actionPrefix + ' Delete Project Failure', props<{ error: Error; uuid: string }>());

export const deleteProjectWithConfirm = createAction(actionPrefix + ' Delete Project with confirm', props<{ uuid: string }>());
