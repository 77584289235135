import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';
import * as ProjectActions from './project.actions';
import * as _ from 'lodash';
import { Meta, RequestOptions } from '@gridscale/gsclient-js';

export interface Project {
  object_uuid?: string;
  create_time?: string;
  change_time?: string;
  name?: string;
  location_uuid?: string;
  default_in_contract?: boolean;
  default_backup_location_uuid?: string;
  backup_center_keep_backups?: number;
  backup_center_schedule_run_interval?: number;
  backup_center_active?: boolean;
  relations?: any;
}

export interface ProjectUpdate {
  name?: string;
  location_uuid?: string;
  default_in_contract?: boolean;
  backup_center_active?: boolean;
  backup_center_schedule_run_interval?: number;
  backup_center_keep_backups?: number;
}

export interface ProjectCreate {
  name?: string;
  location_uuid?: string;
}

export const featureKey = 'project';

export interface ListState {

  loading: boolean;
  loaded: boolean;
  error: Error | HttpErrorResponse | undefined
  data: Project[];
}

export interface EntityState {
  loading: boolean;
  loaded: boolean;
  error: Error | HttpErrorResponse | undefined
  data?: Project;
}
export interface State {
  meta?: Meta;
  listConfig?: RequestOptions;
  loadingNext?: boolean;
  patching?: object;
  error?: object;
  entities: {
    [projectUuid: string]: EntityState;
  };
  list: {
    [contractUuid: string]: ListState;
  }
}
export const initialEntetyState: EntityState = {
  loading: false,
  loaded: false,
  error: undefined,
  data: undefined
};

export const initialListState: ListState = {
  loading: false,
  loaded: false,
  error: undefined,
  data: []
};

export const initialState: State = {
  entities: {},
  list: {},
  patching: {},
  error: {},
  listConfig: {
    limit: 10,
    sort: '-name',
    fields: ['name']
  }
};

export const reducer = createReducer(
  initialState,

  // Store localy and use in the selecor to sort payloads!
  on(ProjectActions.setListConfig, (state, action) => ({
    ...state,
    listConfig: {
      ...action.config
    }
  })),

  on(ProjectActions.loadProject, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      [action.uuid]: {
        ..._.get(state, ['entities', action.uuid], initialEntetyState),
        loading: true,
        loaded: false
      }
    }
  })),

  on(ProjectActions.loadProjectSuccess, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      [action.uuid]: {
        ..._.get(state, ['entities', action.uuid], initialEntetyState),
        loading: false,
        loaded: true,
        data: action.data
      }
    }
  })),

  on(ProjectActions.loadProjectFailure, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      [action.uuid]: {
        ..._.get(state, ['entities', action.uuid], initialEntetyState),
        error: action.error,
        loading: false,
        loaded: true
      }
    }
  })),

  on(ProjectActions.loadProjects, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      [action.contractUuid]: {
        ..._.get(state, ['list', action.contractUuid], initialListState),
        loading: true,
        loaded: false
      }
    }
  })),

  on(ProjectActions.loadProjectsSuccess, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      [action.contractUuid]: {
        ..._.get(state, ['list', action.contractUuid], initialListState),
        error: undefined,
        loading: false,
        loaded: true,
        data: action.data
      }
    }
  })),

  on(ProjectActions.loadProjectsFailure, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      [action.contractUuid]: {
        ..._.get(state, ['list', action.contractUuid], initialListState),
        error: action.error,
        loading: false,
        loaded: true
      }
    }
  })),

  on(ProjectActions.patchProject, (state, action) => ({
    ...state,

    patching: { ...state.patching, [action.uuid]: true },
    error: { ...state.error, [action.uuid]: undefined }
  })),
  on(ProjectActions.patchProjectSuccess, (state, action) => ({
    ...state,

    patching: { ...state.patching, [action.uuid]: false },
    error: { ...state.error, [action.uuid]: undefined }
  })),
  on(ProjectActions.patchProjectFailure, (state, action) => ({
    ...state,

    patching: { ...state.patching, [action.uuid]: false },
    error: { ...state.error, [action.uuid]: action.error }
  }))
);
